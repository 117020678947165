.glitch-wrapper {
  background: repeating-linear-gradient(0deg, #ffedff 25%, #ffefff 50%, #edf6ff 50%, #e4eef8 75%);
  background-size: 10px 10px;
  overflow: hidden;

  font-family: 'Barlow', sans-serif;

  @apply flex flex-1 items-center justify-center text-black;
}

.glitch {
  position: relative;
  font-size: 80px;
  margin-left: 15px;
  @apply text-black;
}

.line {
  &:not(:first-child) {
    position: absolute;
    top: 0;
    left: 0;
  }

  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      animation:
        clip 3000ms $i * -300ms linear infinite,
        glitch#{$i} 500ms random(1000) * -1ms linear infinite;

      @keyframes glitch#{$i} {
        0% {
          transform: translateX(0);
        }
        80% {
          transform: translateX(0);
          color: #fff;
        }
        85% {
          transform: translateX(random(10) - 5px);
          color: #4e9a26;
        }
        90% {
          transform: translateX(random(10) - 5px);
          color: #ac1212;
        }
        95% {
          transform: translateX(random(10) - 5px);
          color: #fff;
        }
        100% {
          transform: translateX(0);
        }
      }
    }
  }
}

@keyframes clip {
  0% {
    clip-path: polygon(0 100%, 100% 100%, 100% 120%, 0 120%);
  }

  100% {
    clip-path: polygon(0 -20%, 100% -20%, 100% 0%, 0 0);
  }
}

.noise-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  overflow: hidden;
  z-index: 10;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: radial-gradient(ellipse at center, rgba(243, 243, 243, 0) 0%, rgba(63, 63, 63, 0.75) 100%);
  }

  background: #272727;
  @apply flex flex-1 items-center justify-center text-black;
}

.dark .noise-wrapper {
  @apply bg-black text-white;
  &:after {
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  }
}

.dark .glitch-wrapper {
  @apply text-white;
  background: repeating-linear-gradient(0deg, #0e0d0e 25%, #0e0d0e 50%, #171819 50%, #171819 75%);
}
.dark .glitch {
  @apply text-white;
}

.noise {
  position: absolute;
  top: -500px;
  right: -500px;
  bottom: -500px;
  left: -500px;
  background: transparent url(/noise.png) 0 0;
  background-size: 320px 320px;
  opacity: 0.35;
  animation: noise 1s steps(8, end) infinite both;
}

@keyframes noise {
  0% {
    transform: translateX(0px, 0px);
  }
  10% {
    transform: translate(-100px, 100px);
  }
  20% {
    transform: translate(150px, -100px);
  }
  30% {
    transform: translate(-100px, 100px);
  }
  40% {
    transform: translate(100px, -150px);
  }
  50% {
    transform: translate(-100px, 200px);
  }
  60% {
    transform: translate(-200px, -100px);
  }
  70% {
    transform: translateY(50px, 100px);
  }
  80% {
    transform: translate(100px, -150px);
  }
  90% {
    transform: translate(0px, 200px);
  }
  100% {
    transform: translate(-100px, 100px);
  }
}
