
/* Works on Chrome, Edge, and Safari */
::-webkit-scrollbar
  width: var(--scrollbar-width)

::-webkit-scrollbar-track
  background: var(--scrollbar-bg)

::-webkit-scrollbar-thumb
  /* color of the scroll thumb */
  background-color: var(--scrollbar-thumb)
  /* roundness of the scroll thumb */
  border-radius: var(--scrollbar-border-radius)
  /* creates padding around scroll thumb */
  border: var(--scrollbar-padding) solid var(--scrollbar-bg)

/* Works on Firefox */
// *
  // scrollbar-width: thin
  // scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-bg)
