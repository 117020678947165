@import '@wolfejs/ui/styles/theme'
@import '@wolfejs/ui/styles/glitch'
@import '@wolfejs/ui/styles/gift-type-select'

nextjs-portal
  display: none

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button,
input[type='number']
  -webkit-appearance: none
  margin: 0
  -moz-appearance: textfield !important
