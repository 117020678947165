.gift-type-select {
  svg {
    max-width: 50px;
    @apply fill-default-900 transition duration-300;
  }
  [data-state='active'] {
    svg {
      @apply fill-white;
    }
  }
}

.dark {
  .gift-type-select {
    svg {
      @apply fill-default-700;
    }
    [data-state='active'] {
      svg {
        @apply fill-white;
      }
    }
  }
}
