@tailwind base
@tailwind components
@tailwind utilities

@import './scrollbars'
@import './inputs'

:focus-visible
  outline: none

.prose
  @apply w-auto max-w-none

  :where(.prose > ul > li > *:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *))
    @apply mb-0
  :where(.prose > ul > li > *:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *))
    @apply mt-0

  .contains-task-list
    @apply list-none pl-0
  li
    @apply my-0

  code
    @apply text-code font-bold
    &:before
      content: ''
    &:after
      content: ''

  pre code
    @apply text-white font-normal

a
  @apply cursor-pointer text-link

.mdx
  @apply p-8 flex-1

.footnotes
  @apply px-8 pb-4

// [type=checkbox], [type=radio]
  // --tw-shadow: 0 0 #0000

  // background-origin: border-box
  // border-width: 1px
  // print-color-adjust: exact

[type=checkbox]:checked
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 12'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M1 5.917 5.724 10.5 15 1.5'/%3E%3C/svg%3E")
  background-size: 1em .55em

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
  -webkit-background-clip: text
  -webkit-text-fill-color: #c76011 !important
  -webkit-box-shadow: 0 0 0px 1000px red inset
  transition: background-color 5000s ease-in-out 0s
  box-shadow: inset 0 0 20px 20px #C5C6C7

body input[data-com-onepassword-filled="dark"]
  background-color: transparent !important

.prose .ui-codeblock
  @apply mb-4

.react-json-view
  @apply overflow-auto w-full

// React Datepicker library
.rhmdp-flex
  @apply flex

.rhmdp-items-center
  @apply items-center

.rhmdp-text-2xl
  @apply text-2xl

.rhmdp-font-bold
  @apply font-bold

.dow.rhmdp-font-bold
  @apply font-normal

.rhmdp-mb-4
  @apply mb-4

.rhmdp-cursor-pointer
  @apply cursor-pointer

.rhmdp-justify-between
  @apply justify-between

.rhmdp-p-4
  @apply p-0 mb-2

.rhmdp-select-none
  @apply select-none

.rhmdp-grid
  @apply grid

.rhmdp-grid-cols-7
  @apply grid-cols-7 text-center

.rhmdp-text-center
  @apply text-center

.rhmdp-border
  @apply border

.rhmdp-border-transparent
  @apply border-transparent

.rhmdp-cursor-pointer
  @apply cursor-pointer

.rhmdp-bg-default-300
  @apply bg-default-300

.rhmdp-p-2
  @apply p-2

.rhmdp-rounded-lg
  @apply rounded-lg

.rhmdp-h-full
  @apply h-full
