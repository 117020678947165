$input-selector: ".input-control"

// SCSS mixin to hide number input arrows
@mixin hide-number-input-arrows
  #{$input-selector}::-webkit-outer-spin-button,
  #{$input-selector}::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

  // Firefox
  #{$input-selector}
    -moz-appearance: textfield

// Example usage of the mixin in a SCSS file
.hide-input-arrows
  // Apply the mixin to .number-input class
  @include hide-number-input-arrows
